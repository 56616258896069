import { jwtDecode } from "jwt-decode";

export const countEnds = (type: string, count: number) => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (type === "iy") {
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return "ий";
    if (lastDigit === 1) return "ие";
    if (lastDigit >= 2 && lastDigit <= 4) return "ия";
    return "ий";
  }

  if (type === "ov") {
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return "ов";
    if (lastDigit === 1) return "";
    if (lastDigit >= 2 && lastDigit <= 4) return "а";
    return "ов";
  }

  return "";
};
/*
  Плагин для пагинации
  import { useInfiniteScroll } from '~/common/helpers.js'

  interface pageMeta {
    page: number,
    pageSize: number,
    pageCount: number,
    totalPages: number,
  }
  
  interface IFunc {
    func: () => {};
  }

  useInfiniteScroll(pageMeta, func);
  watch(() => pageMeta, () => {
    useInfiniteScroll(pageMeta, func);
  })
*/
interface PageMeta {
  page: number;
  pageSize: number;
  pageCount: number;
  totalCount: number;
}

type FetchFunction = (page: number) => Promise<void>;

export function useInfiniteScroll(pageMeta: Ref<PageMeta>, fetchFunction: FetchFunction) {
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const bottomOfWindow = scrollTop + windowHeight >= documentHeight - 1;
    if (bottomOfWindow) {
      const nextPage =
        pageMeta.value.page + 1 <= pageMeta.value.pageCount ? pageMeta.value.page + 1 : null;
      if (!nextPage) return;
      fetchFunction(nextPage);
    }
  };

  onMounted(() => {
    window.addEventListener("scroll", handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
  });
}

interface IJwtPayload {
  [key: string]: any; // Позволяет динамически обращаться к любым полям
  id?: string; // Поле id (опциональное, так как оно может отсутствовать)
}
export function getIdFromJwt(token: string, param: string = "id"): string | null {
  try {
    // Расшифровка токена
    const decoded: IJwtPayload = jwtDecode(token);

    // Проверка, что поле существует и не равно undefined
    if (decoded[param] !== undefined) {
      return decoded[param]; // Возвращаем значение поля
    }
  } catch (error) {
    console.error("Ошибка при расшифровке токена:", error);
  }

  // Если поле отсутствует или произошла ошибка, возвращаем null
  return null;
}

export function getUserId() {
  const token = useCookie("authorizedP").value;
  if (token) return getIdFromJwt(token);
  return null;
}
