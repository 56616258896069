import { defineStore } from "pinia";
import { PublisherStatus } from "~/interfaces/publisher";
import { ElMessageBox, ElNotification } from "element-plus";
import { ApiKey } from "~/common/API/baseApi";
import PublisherService from "~/services/PublisherService";
import type { IAddPublisherPaymentsData } from "~/interfaces/publisher/moderation";
import { getUserId } from "~/common/helpers";

const runtimeConfig = useRuntimeConfig();

let authKeyLink = "/user/telegram/bot/generate-auth-key";
// let checkTgAuthLink = '/user/auth/telegram/get-access-token';
let checkTgAuthLink = "/user/telegram/bot/check-auth";
// let checkTgAuthLink = '/user/bot/publisher/login';
let emailAuthLink = "/user/login";

let timeoutValueToCheckTg = 60000;
let intervalValueToCheckTg = 3000;

export const usePublisherStore = defineStore("publisher", () => {
  const id = ref<number | string>("");
  const name = ref<string>("");
  const companyName = ref<string>("");
  const phone = ref<string>("");
  const balance = ref<number>(0);

  let status = ref<PublisherStatus>(PublisherStatus.NOT_AUTHORIZED);
  let telegram: Ref<string> = ref("");
  let email: Ref<string | null> = ref(null);
  let emailConfirmed: Ref<boolean> = ref(false);
  let authKey: Ref<string> = ref("");
  let authorized: Ref<string> = ref("");

  const roles: Ref<number[]> = ref([]);

  let intervalIdToTgCheck: Ref<number> = ref(0);
  let timeoutIdToTgCheck: Ref<number> = ref(0);

  let loginDialogVisible: Ref<boolean> = ref(false);

  // Взял из стока, зачем пока не понятно
  function setStatus(data: PublisherStatus) {
    status.value = data;
  }

  const userObject = computed(() => {
    return {
      name: name.value,
      companyName: "",
      telegram: telegram.value,
      email: email.value,
      phone: "",
    };
  });

  function getUser() {
    return {
      name: name.value,
      companyName: "",
      telegram: telegram.value,
      email: email.value,
      phone: "",
    };
  }

  const currentStatus = computed(() => {
    // return 3;
    if (!email.value && !emailConfirmed.value && !authorized.value) {
      return 0; // Все поля пустые
    } else if (!telegram.value || telegram.value.length < 3) {
      return 1; // Авторизация через Telegram
    } else if ((!email.value || email.value === "null") && !emailConfirmed.value) {
      return 2; // Не введена почта и не подтверждена
    } else if (email.value && !emailConfirmed.value) {
      return 3; // Введена почта, но не подтверждена
    } else if (email.value && emailConfirmed.value) {
      return 4; // Введены и подтверждены все данные
    } else {
      return 0;
    }
  });

  function setEmail(data: string) {
    email.value = data;
  }

  function getUserChannels() {
    return [];
  }

  function resetAuthKey() {
    name.value = "";
    companyName.value = "";
    phone.value = "";
    authKey.value = "";
    authorized.value = "";
    id.value = "";
    email.value = "";
    emailConfirmed.value = false;
    telegram.value = "";
    roles.value = [];
    setLS();
  } // resetAuthKey

  function setAuthorized(key: string, auth: string, id_v: string) {
    authKey.value = key;
    authorized.value = auth;
    id.value = +id_v;
    setLS();
  } // setAuthorized

  function setLS() {
    localStorage.setItem("usePublisherStore__authKey", authKey.value);
    localStorage.setItem("usePublisherStore__authorized", authorized.value);
    localStorage.setItem("usePublisherStore__id", id.value.toString());
    localStorage.setItem("usePublisherStore__name", name.value);
    localStorage.setItem("usePublisherStore__email", email?.value || "");
    localStorage.setItem(
      "usePublisherStore__emailConfirmed",
      String(emailConfirmed.value || false)
    );
    localStorage.setItem("usePublisherStore__telegram", telegram.value);
    localStorage.setItem("usePublisherStore__roles", JSON.stringify(roles.value));
    useCookie("authorizedP").value = authorized.value;
    useCookie("accessToken").value = authorized.value;
  } // setLS

  function requestAuthKey() {
    const usePublisherStore__authorized = localStorage.getItem("usePublisherStore__authorized");
    if (usePublisherStore__authorized) {
      authorized.value = usePublisherStore__authorized;
      useCookie("authorizedP").value = usePublisherStore__authorized;
    }
    const usePublisherStore__authKey = localStorage.getItem("usePublisherStore__authKey");
    if (usePublisherStore__authKey) {
      authKey.value = usePublisherStore__authKey;
    }
    const usePublisherStore__telegram = localStorage.getItem("usePublisherStore__telegram");
    if (usePublisherStore__telegram) {
      telegram.value = usePublisherStore__telegram;
    }
    const usePublisherStore__email = localStorage.getItem("usePublisherStore__email");
    if (usePublisherStore__email) {
      email.value = usePublisherStore__email;
    }
    const usePublisherStore__name = localStorage.getItem("usePublisherStore__name");
    if (usePublisherStore__name) {
      name.value = usePublisherStore__name;
    }
    const usePublisherStore__roles = localStorage.getItem("usePublisherStore__roles");
    if (usePublisherStore__roles) {
      roles.value = JSON.parse(usePublisherStore__roles);
    }
    const usePublisherStore__emailConfirmed = localStorage.getItem(
      "usePublisherStore__emailConfirmed"
    );
    if (usePublisherStore__emailConfirmed) {
      emailConfirmed.value = usePublisherStore__emailConfirmed === "true";
    }
    const usePublisherStore__id = localStorage.getItem("usePublisherStore__id");
    if (usePublisherStore__id) {
      id.value = usePublisherStore__id;
    }
    if (authorized.value) {
      return;
    }
    if (authKey.value && authKey.value !== "undefined") {
      return;
    }
    $fetch(authKeyLink, {
      baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
      method: "POST",
      onRequest({ request, options }) {
        // Set the request headers
        // options.headers = options.headers || {}
        // options.headers.authorization = '...'
      },
      onRequestError({ request, options, error }) {
        // Handle the request errors
      },
      onResponse({ request, response, options }) {
        // Process the response data
        // TODO: remove console.log
        console.log(555, response);
        authKey.value = response._data.authKey;
        localStorage.setItem("usePublisherStore__authKey", authKey.value);
      },
      onResponseError({ request, response, options }) {
        // Handle the response errors
      },
    });
  } // requestAuthKey

  async function startTelegramAuthorize(): Promise<boolean> {
    try {
      await ElMessageBox.confirm(
        "Вход через Telegram",
        "Перейдите в Telegram и нажмите start в боте",
        {
          showCancelButton: false,
          confirmButtonText: "OK",
        }
      );
      const url = `${useRuntimeConfig().public.RESOLVE_TELEGRAM_AUTHORIZE_PREFIX_PUBLISHER}${authKey.value}`;
      console.log("check url for telegram bot login:");
      console.log(url);
      window.open(url, "_blank");
      loginDialogVisible.value = false;
      return await checkTelegramAuthorize();
    } catch {
      return false;
    }
  } // startTelegramAuthorize

  function checkTelegramAuthorize(): Promise<boolean> {
    return new Promise((resolve) => {
      // Очищаем текущие интервалы и таймауты перед запуском
      clearInterval(intervalIdToTgCheck.value);
      clearTimeout(timeoutIdToTgCheck.value);

      // Устанавливаем таймаут для завершения интервала через указанное время
      timeoutIdToTgCheck.value = window.setTimeout(() => {
        clearInterval(intervalIdToTgCheck.value);
        resolve(false); // Возвращаем false, если истекло время таймаута
      }, timeoutValueToCheckTg);

      // Устанавливаем интервал для проверки авторизации
      intervalIdToTgCheck.value = window.setInterval(() => {
        if (authorized.value && id.value && telegram.value) {
          clearInterval(intervalIdToTgCheck.value);
          clearTimeout(timeoutIdToTgCheck.value);
          resolve(true); // Возвращаем true, если данные успешно получены
          return;
        }

        // Выполняем запрос для проверки статуса авторизации
        let endpoint = checkTgAuthLink;
        if (authorized.value) {
          endpoint = "/user/telegram/bot/user-bind-start";
        }

        $fetch(endpoint, {
          baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
          method: "GET",
          query: {
            authKey: authKey.value,
          },
          onRequest({ request, options }) { },
          onRequestError({ request, options, error }) { },
          onResponse({ request, response, options }) {
            console.log("!!!!!!!!!!!!", response);
            if (response.status === 500) {
              ElNotification({
                title: "Ошибка авторизации",
                message: "попробуйте чуть позже или свяжитесь с администратором...",
                type: "warning",
              });
              clearInterval(intervalIdToTgCheck.value);
              clearTimeout(timeoutIdToTgCheck.value);
            }
            // Если доступен токен авторизации, сохраняем его и останавливаем проверку
            if (response._data.accessToken && response._data.id) {
              authorized.value = response._data.accessToken;
              id.value = response._data.id;
              telegram.value = response._data.telegram;
              ApiKey.set(response._data.accessToken);
              if (response._data.email && !response._data.emailIsFake) {
                email.value = response._data.email;
                emailConfirmed.value = true;
              }
              if (response._data.roles) {
                roles.value = response._data.roles;
              }
              name.value = response._data.name || "";
              companyName.value = response._data.companyName || "";
              phone.value = response._data.phone || "";
              ElNotification({
                title: "Успешная авторизация через Telegram",
                message: "Спасибо!",
                type: "success",
              });
              setLS();
              clearInterval(intervalIdToTgCheck.value);
              clearTimeout(timeoutIdToTgCheck.value);
              status.value = PublisherStatus.AUTHORIZED_IN_BOT;
              resolve(true); // Возвращаем true при успешной авторизации
            } else {
              ElNotification({
                title: "Ожидание авторизации",
                message: "Ждём подтверждения от бота...",
                type: "warning",
              });
            }
          },
          onResponseError({ request, response, options }) { },
        });
      }, intervalValueToCheckTg);
    });
  }

  function startEmailAuthorize({ emaill, passwords }: { emaill: string; passwords: string }) {
    return new Promise((resolve) => {
      $fetch(emailAuthLink, {
        baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
        method: "POST",
        body: {
          username: emaill,
          password: passwords,
        },
        onResponseError({ request, response, options }) {
          resolve(false);
          ElNotification({
            title: "Ошибка авторизации",
            message: "попробуйте чуть позже или свяжитесь с администратором...",
            type: "warning",
          });
        },
        onResponse({ request, response, options }) {
          if (response._data.accessToken && response._data.id) {
            authorized.value = response._data.accessToken;
            ApiKey.set(response._data.accessToken);
            id.value = response._data.id;

            email.value = emaill.toString();
            emailConfirmed.value = true;

            ElNotification({
              title: "Успешная авторизация через Email",
              message: "Спасибо!",
              type: "success",
            });
            setLS();
            status.value = PublisherStatus.VERIFICATED_EMAIL;
            resolve(true); // Возвращаем true при успешной авторизации
          }
        },
      });
    });
  }

  async function setPublisherEmail() {
    if (email.value && id.value) {
      try {
        const res = await PublisherService.setPublisherEmail(email.value, +id.value);
        ElNotification({
          title: "Успешная отправка запроса",
          message: "Спасибо!",
          type: "success",
        });
      } catch (err) {
        ElNotification({
          title: "Ошибка отправки письма",
          message: "попробуйте чуть позже или свяжитесь с администратором...",
          type: "warning",
        });
      }
    } else {
      console.error("email or id not found");
    }
  }

  async function confirmPublisherEmail(code: string) {
    const res = await PublisherService.publisherEmailConfirmation(code);
    if (res && res.email) {
      emailConfirmed.value = true;
      email.value = res.email;
      setLS();
      ElNotification({
        title: "Успешная подтверждение",
        message: "Спасибо!",
        type: "success",
      });
    } else {
      ElNotification({
        title: "Ошибка подтверждения",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "warning",
      });
    }
  }

  interface ISaveData {
    telegram?: string;
    email?: string;
    name?: string;
    newPassword?: string;
  }
  const updateUser = async (data: any) => {
    let saveData: ISaveData = {};

    Object.keys(data).forEach((key) => {
      if (data[key as keyof typeof data] !== "") {
        saveData[key as keyof ISaveData] = data[key as keyof ISaveData];
      }
    });

    if (saveData.telegram && saveData.telegram === telegram.value) {
      delete saveData.telegram;
    }

    if (saveData.email && saveData.email === email.value) {
      delete saveData.email;
    }

    if (saveData.name && saveData.name === name.value) {
      delete saveData.name;
    }

    if (saveData.newPassword) {
      try {
        const passwordResponse = await PublisherService.updateUserPassword(
          +id.value,
          saveData.newPassword
        );
        if (passwordResponse) {
          ElNotification({
            title: "Пароль обновлен",
            message: "Спасибо!",
            type: "success",
          });
        }
      } catch (err) {
        ElNotification({
          title: "Ошибка обновления пароля",
          message: "попробуйте чуть позже или свяжитесь с администратором...",
          type: "warning",
        });
      }
      delete saveData.newPassword;
    }

    if (Object.keys(saveData).length === 0) return;
    const res = await PublisherService.updateUser({ ...saveData, id: +id.value });
    if (res) {
      ElNotification({
        title: "Успешная обновление",
        message: "Спасибо!",
        type: "success",
      });
      Object.keys(saveData).forEach((key) => {
        // const saveKey = key as keyof ISaveData;
        if (key === "email") {
          email.value = saveData[key as keyof ISaveData] || null;
        } else if (key === "name") {
          name.value = saveData[key as keyof ISaveData] || "";
        } else if (key === "telegram") {
          telegram.value = saveData[key as keyof ISaveData] || "";
        }
      });
      setLS();
    }
    return [];
  };

  async function addPublisherPaymentsData(payload: IAddPublisherPaymentsData) {
    try {
      const res = await PublisherService.addPublisherPaymentsData({ ...payload, id: +id.value });
      console.log("addPublisherPaymentsData res", res);
      return res;
    } catch (err) {
      console.error("addPublisherPaymentsData: Error:", err);
    }
  }

  async function getPublisherPaymentsData(isBalance = true) {
    try {
      return await PublisherService.getPublisherPaymentsData(isBalance);
    } catch (err) {
      console.error("getPublisherPaymentsData: Error:", err);
    }
  }

  async function updatePublisherPaymentsData(payload: { id: number; phoneNumber: string }) {
    try {
      const res = await PublisherService.updatePublisherPaymentsData(payload);
      ElNotification({
        title: "Успешная обновление",
        message: "Спасибо!",
        type: "success",
      });
      return res;
    } catch (err) {
      console.error("updatePublisherPaymentsData: Error:", err);
    }
  }
  function logout() {
    resetAuthKey();
  }

  async function getBalance() {
    const res: any = await getPublisherPaymentsData(false);
    // console.log("BALANCE", res);
    balance.value = res?.balance || 0;
  }

  async function paymentAction() {
    return await PublisherService.paymentAction();
  }

  watch(id, async () => {
    if (id.value) {
      await getBalance();
    }
  });

  onMounted(async () => {
    if (!id.value || id.value === "") {
      const token = useCookie("authorizedP").value;
      if (token) {
        const newId = getUserId();
        if (newId) id.value = newId;
      }
    }
  });

  return {
    id,
    name,
    status,
    telegram,
    email,
    emailConfirmed,
    authKey,
    balance,
    authorized,
    loginDialogVisible,
    intervalIdToTgCheck,
    timeoutIdToTgCheck,
    currentStatus,
    roles,
    userObject,
    setLS,
    getUser,
    requestAuthKey,
    resetAuthKey,
    setAuthorized,
    startTelegramAuthorize,
    checkTelegramAuthorize,
    setStatus,
    setEmail,
    getUserChannels,
    startEmailAuthorize,
    logout,
    setPublisherEmail,
    confirmPublisherEmail,
    updateUser,
    addPublisherPaymentsData,
    getPublisherPaymentsData,
    updatePublisherPaymentsData,
    paymentAction,
  };
});
