import { simpleApi } from "~/common/API/simpleApi";
import { ElNotification } from "element-plus";
import type {
  IModerationUpdateRequest,
  PublisherResponse,
} from "~/interfaces/publisher/moderation";

export default class PublisherService {
  static getPublisherPlaces = (userId: number, moderate = false) => {
    let endpoint = "/place/telegram/search";
    if (moderate)
      endpoint = "/publisher/moderation/search?status[]=rejected&status[]=pending&status[]=rework";
    return new Promise((resolve) => {
      simpleApi(endpoint, {
        params: {
          userId,
          perPage: 1000,
        },
      })
        .then((res: any) => {
          // let result = res.records;
          // console.log('result', result);
          // if (moderate) {
          //   result = result.filter(item => item.status === 'pending' || item.status === 'rework' || item.status === 'rejected');
          // }
          resolve(res.records);
        })
        .catch((err) => {
          console.error("getPublisherPlaces", err);
          resolve(false);
          ElNotification({
            title: "Ошибка получения данных",
            message: "попробуйте чуть позже или свяжитесь с администратором...",
            type: "error",
          });
        });
    });
  };

  static getPublisherOrders = (params: any) => {
    const url = `/advert/publications`;
    return new Promise((resolve) => {
      simpleApi(url, {
        params,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static getPublicationCount = (userId: number) => {
    const url = `/publisher/publication/count`;
    return new Promise((resolve) => {
      simpleApi(url, {
        params: {
          id: userId,
          perPage: 100,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve([]);
        });
    });
  };

  static setPublisherEmail = (email: string, id: number) => {
    const url = `/user/set-email-request`;
    return new Promise((resolve) => {
      simpleApi(url, {
        method: "POST",
        body: { email, id },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve(false);
        });
    });
  };

  static publisherEmailConfirmation = async (code: string): Promise<PublisherResponse | null> => {
    const url = `/user/email-confirm`;
    try {
      const res: any = await simpleApi(url, {
        method: "POST",
        body: { code },
      });
      if (!res) return null;
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static getPlaceModeration = (userId: number) => {
    const url = `/moderation/search`;
    return new Promise((resolve) => {
      simpleApi(url, {
        params: {
          "status[]": "rework",
          userId,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve(false);
        });
    });
  };

  static updateModerationRequest = (data: IModerationUpdateRequest) => {
    const url = `/publisher/edit-place-moderation-request`;
    return new Promise((resolve) => {
      simpleApi(url, {
        method: "PUT",
        body: data,
      }).then((res) => {
        resolve(res);
      });
    });
  };

  static updateUser = async (data: {
    id: number;
    name?: string;
    email?: string;
    telegram?: string;
    telegramId?: number;
  }) => {
    const response = await simpleApi('/user/update', {
      method: 'PUT',
      body: data,
      params: { id: data.id }
    });
    return response;

  };

  static orderReject = async (payload: { id: number; reason: string }) => {
    const endpoint = "/advert/publication/publisher-reject";
    try {
      const response = await simpleApi(endpoint, { method: "PUT", body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static orderCancel = async (payload: { id: number; reason: string }) => {
    const endpoint = "/advert/publication/publisher-cancel";
    try {
      const response = await simpleApi(endpoint, { method: "PUT", body: payload });
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  static orderApprove = async (payload: {
    publicationDateTime: string;
    comment?: string;
    id: number;
  }) => {
    const endpoint = "/advert/publication/publisher-approve";
    try {
      const response = await simpleApi(endpoint, { method: "PUT", body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log(666, error);
    }
  };

  static getPlaceOrder = async (payload: { placeId: number }) => {
    const endpoint = "/advert/publications";
    try {
      const response = await simpleApi(endpoint, { params: { ...payload, perPage: 100 } });
      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static sendLink = async (payload: { url: string; id: number }) => {
    const endpoint = "/advert/publication/set-link";
    try {
      return await simpleApi(endpoint, {
        method: "PUT",
        body: { ...payload },
      });
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static addPublisherPaymentsData = async (payload: {
    type?: string;
    fullName?: string;
    country?: string;
    phoneNumber?: string;
    inn?: string;
    cardNumber?: string;
    id: number;
  }) => {
    const endpoint = "/user/payments/details/create";

    try {
      return await simpleApi(endpoint, {
        method: "POST",
        body: payload,
      });
    } catch (err) {
      console.error("addPublisherPaymentsData: Error:", err);
      return null;
    }
  };

  static getPublisherPaymentsData = async (isBalance = true) => {
    const endpoint = "/user/wallet/default";
    let result: any = null;
    try {
      const response: any = await simpleApi(endpoint);
      // if (response?.error && response?.error?.code === 1513) {
      //   return null;
      // }
      if (!response?.error) {
        result = { ...response };
      }
      if (!isBalance) {
        return result;
      }
      const endpointOne = "/finance/acquiring/t-bank/get-card-list";
      const responseOne: any = await simpleApi(endpointOne, { method: "GET" });
      result = { ...result, ...responseOne };
    } catch (err) {
      console.error(123, err);
    } finally {
      return result;
    }
  };

  static updatePublisherPaymentsData = async (payload: { id: number; phoneNumber: string }) => {
    const endpoint = "/user/payments/details/update";
    try {
      const response: any = await simpleApi(endpoint, { method: "PUT", body: payload });
      return response;
    } catch (err) {
      console.error("updatePublisherPaymentsData: Error:", err);
      return null;
    }
  };

  static getPlaceOrderCount = async (placeId: number) => {
    const endpoint = "/advert/orders/count";
    try {
      const response: any = await simpleApi(endpoint, { params: { publisherId: placeId } });
      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  static updateUserPassword = async (id: number, password: string) => {
    const endpoint = "/user/set-password";
    try {
      const response: any = await simpleApi(endpoint, { method: "POST", body: { id, password } });
      return response;
    } catch (err) {
      console.error(err);
      ElNotification({
        title: "Ошибка обновления пароля",
        message: "попробуйте чуть позже или свяжитесь с администратором...",
        type: "error",
      });
      return null;
    }
  };

  static paymentAction = async () => {
    const endpoint = "/finance/acquiring/t-bank/add-card";
    try {
      const response: any = await simpleApi(endpoint, { method: "POST" });
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  static getPlaceStatistic = async (placeId: number) => {
    const endpoint = "/place/telegram/statistic-chart";
    try {
      const response: any = await simpleApi(endpoint, { params: { id: placeId } });
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  static actionPayment = async () => {
    const endpoint = "/finance/acquiring/withdrawal";
    try {
      const response: any = await simpleApi(endpoint, { method: "POST" });
      console.log(response);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}
