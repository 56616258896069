import { usePublisherStore } from "~/store/PublisherModule";

type RequestMethods = "GET" | "POST" | "PUT" | "DELETE";

interface IRequestOptions {
  method?: RequestMethods;
  body?: any;
  params?: Record<string, any>;
  query?: string;
}

// Start of Selection
export const simpleApi = async (endpoint: string, options: IRequestOptions = {}) => {
  // console.log(111, endpoint, options);
  const query = options.query || ""; // Получаем строку запроса или устанавливаем пустую строку, если она не задана
  try {
    const config = useRuntimeConfig(); // Получаем конфигурацию среды выполнения
    const store = usePublisherStore(); // Получаем доступ к хранилищу Publisher

    const apiPublisherUrl = config.public.LIGHT_API_ORIGIN; // URL API из конфигурации

    // Получаем токен из куки или из хранилища, если куки не определены
    const token =
      useCookie("authorizedP").value && useCookie("authorizedP").value !== "undefined"
        ? useCookie("authorizedP").value
        : localStorage.getItem("usePublisherStore__authorized")
          ? localStorage.getItem("usePublisherStore__authorized")
          : store.authorized;

    // Устанавливаем заголовки для запроса
    const headers: any = {
      "Content-Type": "application/json", // Указываем тип содержимого
      // "X-Api-Key": `${token}`, // Добавляем токен в заголовок
      Authorization: `Bearer ${token}`,
    };
    if (!token) {
      delete headers.Authorization; // Удаляем заголовок токена, если токен не определен
    }

    // Выполняем запрос с использованием $fetch
    const response = await $fetch(`${apiPublisherUrl}${endpoint}${query}`, {
      method: options.method || "GET", // Устанавливаем метод запроса, по умолчанию GET
      body: options.body, // Передаем тело запроса, если оно есть
      headers, // Передаем заголовки
      params: options.params, // Передаем параметры запроса, если они есть
    });
    return response; // Возвращаем ответ
  } catch (error: any) {
    // Проверяем, есть ли оригинальный ответ сервера
    const serverError = error.data || error.response?.data || error;

    if (serverError.code === 1513) {
      return { error: serverError };
    }

    if (serverError.code === 2800) {
      return { error: error.statusCode };
    }

    if (error.statusCode === 401) {
      usePublisherStore().logout();
      navigateTo("/");
      return;
    }

    // Логируем полный объект ошибки
    console.error("Full error details:", {
      status: error.statusCode,
      message: serverError.message,
      code: serverError.code,
      debug: serverError.debug,
      timestamp: serverError.timestamp,
    });

    // Обрабатываем ошибку и выбрасываем новое исключение с сообщением об ошибке
    throw new Error(serverError.message || `Query error for ${endpoint}`);
  }
};
